import React from "react";
import Clock from "./Clock";
import KonnectClock from "./KonnectClock";
import { ProviderBrands } from "./common/Constants";

class Main extends React.Component {
  constructor(props) {
    super(props);
    const queryParams = new URLSearchParams(window.location.search);
    this._brand = queryParams.get("providerbrand");
    console.log("ProviderBrand", this._brand);
  }

  render() {
    return this._brand?.toLowerCase() === ProviderBrands.konnect ? (
      <KonnectClock />
    ) : (
      <Clock />
    );
  }
}

export default Main;
