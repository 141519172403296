export const Themes = {
  dark: "dark",
  light: "light",
};

export const Languages = {
  english: "en",
  spanish: "sp",
  french: "fr",
  dutch: "du",
};

export const ClockTypes = {
  analog: "analog",
  digital: "digital",
};

export const TimeFormats = {
  12: "12",
  24: "24",
};

export const MonthNames = [
  "JANUARY",
  "FEBRUARY",
  "MARCH",
  "APRIL",
  "MAY",
  "JUNE",
  "JULY",
  "AUGUST",
  "SEPTEMBER",
  "OCTOBER",
  "NOVEMBER",
  "DECEMBER",
];

export const DayNames = [
  "SUNDAY",
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
];

export const TimeOfDay = ["NIGHT", "MORNING", "AFTERNOON", "EVENING"];
