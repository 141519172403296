import React from "react";
import "@fontsource/roboto";
import "../styles/Components.scss";
import "react-clock/dist/Clock.css";
import Grid from "@mui/material/Grid";
import AnalogClock from "react-clock";
import {
  ClockTypes,
  DayNames,
  Languages,
  MonthNames,
  Themes,
  TimeFormats,
  TimeOfDay,
} from "../constants/constants";

const LangStrings = require("../config/translations.json");

const DefaultTheme = Themes.dark;
const DefaultLanguage = Languages.english;
const DefaultClockType = ClockTypes.digital;
const DefaultTimeFormat = TimeFormats[24];
const DefaultContrast = true;

class Clock extends React.Component {
  constructor(props) {
    super(props);
    console.log("Clock props", props);

    /** get query params */
    const queryParams = new URLSearchParams(window.location.search);
    this._lang = queryParams.get("language") || DefaultLanguage;
    this._theme = queryParams.get("theme") || DefaultTheme;
    this._type = queryParams.get("type") || DefaultClockType;
    this._format = queryParams.get("format") || DefaultTimeFormat;
    this._contrast =
      (queryParams.get("contrast") || DefaultContrast) === "true";
    console.log(
      `Clock params > Theme: ${this._theme}, Language: ${this._lang}, Clock Type: ${this._type}, Clock Format: ${this._format}, Clock Contrast: ${this._contrast}`
    );

    this.state = { date: new Date() };
  }

  componentDidMount() {
    this.timerID = setInterval(() => this.tick(), 1000);
    window.addEventListener("resize", this.resetParams);
    this.resetParams();
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
    window.removeEventListener("resize", null);
  }

  resetParams = () => {
    const { innerWidth: width, innerHeight: height } = window;
    const params = {
      /* Digital Clock */
      frameMargin: Math.ceil(height * 0.2),
      fontSize1: Math.ceil(height * 0.34),
      fontSize2: Math.ceil(height * 0.16),
      fontSize3: Math.ceil(height * 0.07),
      /* Analog Clock */
      frameMargin1: Math.ceil(height * 0.05),
      clockSize: Math.ceil(height * 0.55),
      clockNumberSize: Math.ceil(height * 0.035),
      font1: Math.ceil(height * 0.18),
      font2: Math.ceil(height * 0.12),
      font3: Math.ceil(height * 0.08),
      spacing: Math.ceil(height * 0.08),
    };
    console.log("window...", width, height, params);
    this.setState({ params });
  };

  tick = () => {
    this.setState({ date: new Date() });
  };

  dayName = () => {
    const trans = LangStrings?.dayNames || {};
    const dayNames = trans[this._lang] || DayNames;
    const day = this.state.date.getDay();
    return dayNames[day] || DayNames[day];
  };

  monthName = () => {
    const trans = LangStrings?.monthNames || {};
    const monthNames = trans[this._lang] || MonthNames;
    const month = this.state.date.getMonth();
    return monthNames[month] || MonthNames[month];
  };

  timeOfDay = () => {
    const trans = LangStrings?.timeOfDay || {};
    const timeOfDay = trans[this._lang] || TimeOfDay;
    const hours = this.state.date.getHours();
    /**
     * HOMESIGHT-962
     * 00:00 to 06:00 > Night (12pm-6am)
     * 06:00 to 11:59 > Morning (6am-12pm)
     * 12:00 to 17:59 > Afternoon (12pm-6pm)
     * 18:00 to 23:59 > Evening (6pm-12pm)
     */
    let index = 0;
    if (hours >= 18) index = 3;
    else if (hours >= 12) index = 2;
    else if (hours >= 6) index = 1;
    return timeOfDay[index] || TimeOfDay[index];
  };

  timeString = () => {
    const minutes = this.state.date.getMinutes();
    const hours = this.state.date.getHours();
    const minuteString = ("0" + minutes).slice(-2);
    const hourString = ("0" + hours).slice(-2);
    let time = "";
    if (this._format === TimeFormats[24]) {
      time = hourString + ":" + minuteString;
    } else {
      var ampm = hours >= 12 ? "PM" : "AM";
      const newHours = hours % 12;
      // const newHourString = ("0" + newHours).slice(-2);
      time = newHours + ":" + minuteString + " " + ampm;
    }
    return time;
  };

  render() {
    const { date, params = {} } = this.state;
    const isDigital = this._type === ClockTypes.digital;
    const analogClassname = `analog-clock ${
      this._contrast
        ? this._theme === Themes.dark
          ? "light-clock"
          : "dark-clock"
        : this._theme === Themes.dark
        ? "dark-clock"
        : "light-clock"
    }`;

    return (
      <div className={`clock main-container ${this._theme}-theme`}>
        <Grid className="main-grid">
          {isDigital ? (
            <div
              className="clock-container"
              style={{ margin: `${params.frameMargin}px` }}
            >
              <span
                className="header"
                style={{
                  fontSize: `${params.fontSize2}px`,
                  lineHeight: `${params.fontSize2}px`,
                }}
              >
                {this.dayName()}
              </span>

              <span
                className="header"
                style={{
                  fontSize: `${params.fontSize3}px`,
                  lineHeight: `${params.fontSize3}px`,
                }}
              >
                {this.timeOfDay()}
              </span>

              <span
                className="header"
                style={{
                  fontSize: `${params.fontSize1}px`,
                  lineHeight: `${params.fontSize1 * 1.4}px`,
                }}
              >
                {this.timeString()}
              </span>

              <span
                className="header"
                style={{
                  width: "85%",
                  display: "flex",
                  justifyContent: "space-between",
                  fontSize: `${params.fontSize3}px`,
                  lineHeight: `${params.fontSize3}px`,
                }}
              >
                <span style={{ margin: "0 10px" }}>{date.getDate()}</span>
                <span style={{ margin: "0 10px" }}>{this.monthName()}</span>
                <span style={{ margin: "0 10px" }}>{date.getFullYear()}</span>
              </span>
            </div>
          ) : (
            <div
              className="flex-align-just-center"
              style={{
                height: "100%",
                margin: `${params.frameMargin1}px`,
              }}
            >
              <div
                className="flex-align-just-center"
                style={{ fontSize: `${params.clockNumberSize}px` }}
              >
                <AnalogClock
                  className={analogClassname}
                  value={date}
                  size={params.clockSize}
                  renderNumbers={true}
                  minuteMarksLength={5}
                  renderSecondHand={true}
                />
              </div>
              <div
                className="clock-container analog-text-container"
                style={{ marginLeft: `${params.spacing}px` }}
              >
                <div className="flex-column flex-align-center">
                  <span
                    className="header"
                    style={{
                      fontSize: `${params.font1}px`,
                      lineHeight: `${params.font1 + 20}px`,
                    }}
                  >
                    {this.dayName()}
                  </span>

                  <span
                    className="header"
                    style={{
                      fontSize: `${params.font2}px`,
                      lineHeight: `${params.font2 + 20}px`,
                      marginBottom: `${params.font2 * 0.7}px`,
                    }}
                  >
                    {this.timeOfDay()}
                  </span>

                  <span
                    className="header"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      fontSize: `${params.font3}px`,
                      lineHeight: `${params.font3 + 20}px`,
                    }}
                  >
                    <span style={{ margin: "0 20px" }}>{date.getDate()}</span>
                    <span style={{ margin: "0 20px" }}>{this.monthName()}</span>
                    <span style={{ margin: "0 20px" }}>
                      {date.getFullYear()}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          )}
        </Grid>
      </div>
    );
  }
}

export default Clock;
